import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;


const dashboardGet = async (token,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/dasboard?branchId=${branchId}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Dasboard api failed');
};


const dashboardServices = {
    dashboardGet,
};

export default dashboardServices;
