import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { paths } from 'src/routes/paths';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData} from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import { companyRolepremissionsGet } from 'src/redux/features/premission/premissionSlice';

export default function NavVertical({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lgUp = useResponsive('up', 'lg');
  const navData = useNavData();

  const { currentUser } = useSelector((state) => state.auth);
  const { premissionAgainstRole } = useSelector((state) => state.premission);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if(!currentUser?.userType){
    dispatch(companyRolepremissionsGet(currentUser?.company_role?._id));
    }
  }, []);

  const filterNavData = (navData, premissionAgainstRole, userType) => {
    if (userType) {
      return navData;
    }
    const allowedTitles = new Set(
      premissionAgainstRole?.map((permission) => permission?.title?.toLowerCase())
    );
    return navData
      ?.map((section) => {
        const filteredItems = section?.items?.filter((item) => {
          if (item?.title?.toLowerCase() === 'dashboard') return true;
          return allowedTitles?.has(item.title.toLowerCase());
        });

        if (filteredItems?.length > 0) {
          return { ...section, items: filteredItems };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredNavData = filterNavData(navData, premissionAgainstRole, currentUser?.userType);

  const isValidPath = (pathname, filteredNavData) => {
    return filteredNavData?.some((section) =>
      section.items.some((item) => {
        if (item?.path === pathname) {
          return true;
        }
        if (item?.children) {
          return item?.children?.some((child) => child?.path === pathname || pathname?.startsWith(child?.path));
        }
        return false;
      })
    );
  };


  useEffect(() => {
    if (!isValidPath(pathname, filteredNavData)) {
      navigate(paths.dashboard.root);
    }
  }, [pathname, filteredNavData, navigate]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
        <NavSectionVertical
          data={filteredNavData}
          config={{
            currentRole:'admin',
          }}
        />
      <Box sx={{ flexGrow: 1 }} />
      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
