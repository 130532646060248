import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addInvoice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/invoice`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Invoice api failed');
};
const invoiceGet = async (token, pageno, limit, branch,type,search,startDate,endDate,status) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/invoice?branch=${branch}&page=${pageno}&perPage=${limit}&search=${search??''}&startDate=${startDate??''}&endDate=${endDate??''}&status=${status??''}&type=${type??''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Invoice List api failed');
};
const invoiceStatsGet = async (token, branch,type) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/invoice/stats?branch=${branch}&type=${type??''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Invoice stats api failed');
};
const invoiceSingleGet = async (token,id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/single/invoice/${id}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Invoice Single Get api failed');
};
const updateInvoice = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/invoice/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated invoice api failed');
};
const invoiceDetailGet = async (token,id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/detail/invoice/${id}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Invoice detail Get api failed');
};

const invoiceServices = {
  addInvoice,
  invoiceGet,
  invoiceStatsGet,
  invoiceSingleGet,
  updateInvoice,
  invoiceDetailGet
};

export default invoiceServices;
