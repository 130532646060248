import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addFiscalYear = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/fiscalYear`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Fiscal Year api failed');
};

const fiscalYear = async (token, pageno, limit, branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/fiscalYear?branch=${branch}&page=${pageno}&perPage=${limit}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Fiscal Year List api failed');
};

const updateFiscalYear = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/fiscalYear/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Fiscal Year api failed');
};

const fiscalYearServices = {
    addFiscalYear,
    fiscalYear,
    updateFiscalYear,
};

export default fiscalYearServices;
