import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import { paths } from 'src/routes/paths';
import { NavSectionMini } from 'src/components/nav-section';
import { usePathname } from 'src/routes/hooks';
//
import { NAV } from '../config-layout';
import { useNavData} from './config-navigation';
import { NavToggleButton } from '../_common';
import { companyRolepremissionsGet } from 'src/redux/features/premission/premissionSlice';

export default function NavMini() {

  const pathname = usePathname();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const navData = useNavData();

  const { currentUser } = useSelector((state) => state.auth);
  const { premissionAgainstRole } = useSelector((state) => state.premission);

  useEffect(() => {
    if(!currentUser?.userType){
    dispatch(companyRolepremissionsGet(currentUser?.company_role?._id));
    }
  }, []);

  const filterNavData = (navData, premissionAgainstRole, userType) => {
    if (userType) {
      return navData;
    }
    const allowedTitles = new Set(
      premissionAgainstRole?.map((permission) => permission?.title?.toLowerCase())
    );
    return navData
      ?.map((section) => {
        const filteredItems = section?.items?.filter((item) => {
          if (item?.title?.toLowerCase() === 'dashboard') return true;
          return allowedTitles?.has(item.title.toLowerCase());
        });

        if (filteredItems?.length > 0) {
          return { ...section, items: filteredItems };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredNavData = filterNavData(navData, premissionAgainstRole, currentUser?.userType);

  const isValidPath = (pathname, filteredNavData) => {
    return filteredNavData?.some((section) =>
      section?.items?.some((item) => {
        if (item?.path === pathname) {
          return true;
        }
        if (item?.children) {
          return item?.children?.some((child) => child?.path === pathname || pathname?.startsWith(child?.path));
        }
        return false;
      })
    );
  };

  useEffect(() => {
    if (!isValidPath(pathname, filteredNavData)) {
      navigate(paths.dashboard.root);
    }
  }, [pathname, filteredNavData, navigate]);


  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />
          <NavSectionMini
            data={filteredNavData}
            config={{
              currentRole: 'admin',
            }}
          />
      </Stack>
    </Box>
  );
}
