import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;


const branchDetailGet = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/branch-details?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Branch Detail api failed');
};


const branchServices = {
    branchDetailGet,
};

export default branchServices;