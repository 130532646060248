import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from "./features/auth/authSlice";
import { accountChartSlice } from "./features/account-chart/accountChartSlice";
import { roleSlice } from "./features/role/roleSlice";
import { userSlice } from "./features/user/userSlice";
import { taxSlice } from "./features/tax/taxSlice";
import { branchSlice} from './features/branch/branchSlice';
import {invoiceSlice} from './features/invoice/invoiceSlice';
import {transactionSlice} from './features/transaction/transactionSlice';
import {dashboardSlice} from './features/dashboard/dashboardSlice';
import {reportSlice} from './features/report/reportSlice';
import {contactSlice} from './features/contact/contactSlice';
import {fiscalYearSlice} from './features/fiscal-year/fiscalYearSlice';
import {premissionSlice} from './features/premission/premissionSlice';



const rootReducer = combineReducers({
  auth:authSlice.reducer,
  accountChart:accountChartSlice.reducer,
  role:roleSlice.reducer,
  user:userSlice.reducer,
  tax:taxSlice.reducer,
  branch:branchSlice.reducer,
  invoice:invoiceSlice.reducer,
  transaction:transactionSlice.reducer,
  dashboard:dashboardSlice.reducer,
  report:reportSlice.reducer,
  contact:contactSlice.reducer,
  fiscalYear:fiscalYearSlice.reducer,
  premission:premissionSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [`auth`],
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
});

export const persistor = persistStore(store);
