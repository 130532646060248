import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addTransaction = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/transaction`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Transaction api failed');
};

const transaction = async (token, pageno, limit, branch, narration, date,taxName) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/transaction?branch=${branch}&page=${pageno}&perPage=${limit}&narration=${
      narration ?? ''
    }&date=${date ?? ''}&taxName=${taxName??''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Transaction List api failed');
};

const updateTransaction = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/transaction/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Transaction api failed');
};

const accountChartServices = {
  addTransaction,
  transaction,
  updateTransaction,
};

export default accountChartServices;
