import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const premissionsGet = async (token, company) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(
        `${BASE_URL}/admin/premission?company=${company}`,
        config
    );
    if (response.data) {
        return response.data;
    }
    throw new Error('Premission List api failed');
};
const permissionToggle = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(`${BASE_URL}/admin/premission`, data, config);
    if (response.data) {
        return response.data;
    }
    throw new Error('Permission Toggle api failed');
};
const companyRolepremissionsGet = async (token, companyRole) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(
        `${BASE_URL}/admin/role/premission/${companyRole}`,
        config
    );
    if (response.data) {
        return response.data;
    }
    throw new Error('Premission against Role api failed');
};

const premissionServices = {
    premissionsGet,
    permissionToggle,
    companyRolepremissionsGet
};

export default premissionServices;
