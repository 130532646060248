import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);



const icon_settings = (name) => (
  <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  contact: icon('ic_user'),
  user: icon('ic_users'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  report: icon('ic_calendar'),
  settings: icon_settings('ic_setting'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
        ],
      },
      // Accounting
      {
        subheader: t('Management'),
        items: [
          // USER
          {
            title: t('users'),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: t('list'), path: paths.dashboard.user.list },
              { title: t('create'), path: paths.dashboard.user.new },
            ],
          },
          // Contact
          {
            title: t('contacts'),
            path: paths.dashboard.contact.root,
            icon: ICONS.contact,
            children: [
              { title: t('list'), path: paths.dashboard.contact.root },
              { title: t('create'), path: paths.dashboard.contact.new },
            ],
          },
          // INVOICE
          {
            title: t('invoices'),
            path: paths.dashboard.invoice.root,
            icon: ICONS.invoice,
            children: [
              { title: t('list'), path: paths.dashboard.invoice.root },
              { title: t('create'), path: paths.dashboard.invoice.new },
            ],
          },
          // Accounting
          {
            title: t('accounting'),
            path: paths.dashboard.accounting.root,
            icon: ICONS.banking,
            children: [
              { title: t('create'), path: paths.dashboard.accounting.new },
              { title: t('View Transactions'), path: paths.dashboard.accounting.transaction },
              { title: t('VAT Declaration'), path: paths.dashboard.accounting.vat_declaration },
              { title: t('Balance Tax Rate'), path: paths.dashboard.accounting.balance_tax_rate },
              { title: t('Year End Procedure'), path: paths?.dashboard?.accounting?.year_end_procedure }
            ],
          },
          //Reports
          {
            title: t('reports'),
            path: paths.dashboard.reports.root,
            icon: ICONS.report,
            children: [
              { title: t('Income Statement'), path: paths.dashboard.reports.incomeStatement },
              { title: t('Balance Sheet'), path: paths.dashboard.reports.balanceSheet },
              { title: t('VAT Report'), path: paths.dashboard.reports.vat },
            ],
          },
           {
            title: t('Roles & Permission'),
            path: paths.dashboard.roleRoot,
            icon: ICONS.menuItem,
            children: [
              { title: t('User Roles'), path: paths.dashboard.role },
              { title: t('Permissions'), path: paths.dashboard.premissions },
            ],
          },
          // Payroll Module 

          // {
          //   title: t('Payroll'),
          //   path: paths.dashboard.payroll.root,
          //   icon: ICONS.external,
          //   children: [
          //     { title: t('Process Payroll'), path: paths.dashboard.payroll.list },
          //     { title: t('Employee Payslip'), path: paths.dashboard.payroll.new },
          //   ],
          // },

          // Settings
          {
            title: t('settings'),
            path: paths.dashboard.settings,
            icon: ICONS.settings
          }
        ],
      },

    ],
    [t]
  );

  return data;
}