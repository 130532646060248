import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addUser = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/branchUser/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add User api failed');
};

const userGet = async (token, pageno, limit, branch,role,search,isactive) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/branchUser?branch=${branch}&page=${pageno}&perPage=${limit}&role=${role??""}&search=${search??''}&isactive=${isactive??''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('User List api failed');
};

const userStatsGet = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/branchUser/stats?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('User Stats api failed');
};

const updateUser = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/branchUser/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated User api failed');
};

const userServices = {
    addUser,
    userGet,
    updateUser,
    userStatsGet,
};

export default userServices;
