import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import branchServices from './branchServices';

const initialState = {
  branchDetail:{},
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const branchDetailGet = createAsyncThunk(
  'branchDetailGet',
  async (branch,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await branchServices.branchDetailGet(user.accesstoken,branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(branchDetailGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(branchDetailGet.fulfilled, (state, action) => {
        state.branchDetail = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(branchDetailGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.branchDetail = {};
      });
  },
});

export const { resets } = branchSlice.actions;

export default branchSlice.reducer;
