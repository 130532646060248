import { memo,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// components
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { NavSectionHorizontal } from 'src/components/nav-section';

import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';
import { companyRolepremissionsGet } from 'src/redux/features/premission/premissionSlice';

function NavHorizontal() {
  const pathname = usePathname();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const navData = useNavData();

  const { currentUser } = useSelector((state) => state.auth);
  const { premissionAgainstRole } = useSelector((state) => state.premission);
  useEffect(() => {
    if(!currentUser?.userType){
    dispatch(companyRolepremissionsGet(currentUser?.company_role?._id));
    }
  }, []);

  const filterNavData = (navData, premissionAgainstRole, userType) => {
    if (userType) {
      return navData;
    }
    const allowedTitles = new Set(
      premissionAgainstRole?.map((permission) => permission?.title?.toLowerCase())
    );
    return navData
      ?.map((section) => {
        const filteredItems = section?.items?.filter((item) => {
          if (item?.title?.toLowerCase() === 'dashboard') return true;
          return allowedTitles?.has(item.title.toLowerCase());
        });

        if (filteredItems?.length > 0) {
          return { ...section, items: filteredItems };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredNavData = filterNavData(navData, premissionAgainstRole, currentUser?.userType);

  const isValidPath = (pathname, filteredNavData) => {
    return filteredNavData?.some((section) =>
      section.items.some((item) => {
        if (item?.path === pathname) {
          return true;
        }
        if (item?.children) {
          return item?.children?.some((child) => child?.path === pathname || pathname?.startsWith(child?.path));
        }
        return false;
      })
    );
  };


  useEffect(() => {
    if (!isValidPath(pathname, filteredNavData)) {
      navigate(paths.dashboard.root);
    }
  }, [pathname, filteredNavData, navigate]);


  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal
          data={filteredNavData}
          config={{
            currentRole: 'admin',
          }}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
