import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addCompanyRole = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/role`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Company Role api failed');
};

const companyRole = async (token, pageno, limit, company) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/role?company=${company}&page=${pageno}&perPage=${limit}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Company Role List api failed');
};

const updateCompanyRole = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/role/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Company Role api failed');
};

const activecompanyRoleGet = async (token,company) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/active/role?company=${company}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Active Company Role Get api failed');
};

const roleServices = {
    addCompanyRole,
    companyRole,
    updateCompanyRole,
    activecompanyRoleGet
};

export default roleServices;
