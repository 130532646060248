import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import premissionServices from './premissionServices';

const initialState = {
    premissions: [],
    premissionAgainstRole:[],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
};

export const premissionsGet = createAsyncThunk(
    'premissionsGet',
    async (company, thunkAPI) => {
        try {
            const user = JSON.parse(localStorage.getItem('currentUser'));
            return await premissionServices.premissionsGet(user.accesstoken, company);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const permissionToggle = createAsyncThunk('permissionToggle', async (data, thunkAPI) => {
    try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await premissionServices.permissionToggle(user.accesstoken, data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
export const companyRolepremissionsGet = createAsyncThunk(
    'companyRolepremissionsGet',
    async (companyRole, thunkAPI) => {
        try {
            const user = JSON.parse(localStorage.getItem('currentUser'));
            return await premissionServices.companyRolepremissionsGet(user.accesstoken, companyRole);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const premissionSlice = createSlice({
    name: 'premission',
    initialState,
    reducers: {
        resets: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(premissionsGet.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(premissionsGet.fulfilled, (state, action) => {
                state.premissions = action?.payload?.body;
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(premissionsGet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.premissions = [];
            })
            .addCase(permissionToggle.pending, (state) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(permissionToggle.fulfilled, (state, action) => {
                state.premissions = state.premissions.map((role) =>
                    role._id === action?.payload?.body?.companyRole
                        ? {
                            ...role,
                            permissions: role?.permissions?.some((perm) => perm?._id === action?.payload?.body?._id)
                                ? role?.permissions?.filter((perm) => perm?._id !== action?.payload?.body?._id)
                                : [...role?.permissions, action?.payload?.body],
                        }
                        : role
                );
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(permissionToggle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(companyRolepremissionsGet.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(companyRolepremissionsGet.fulfilled, (state, action) => {
                state.premissionAgainstRole = action?.payload?.body;
                state.isSuccess = true;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(companyRolepremissionsGet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { resets } = premissionSlice.actions;

export default premissionSlice.reducer;
