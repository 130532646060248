import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const login = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/login`, data);
  if (response?.data) {
    return response.data;
  }
  throw new Error('Login api failed');
};

const register = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/signup`, data);
  if (response.data) {
    return response.data;
  }
  throw new Error('Register api failed');
};

const verifyEmail = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/verifyEmail`, data);
  if (response.data) {
    return response.data;
  }
  throw new Error('Verify Email api failed');
};

const singleCompanyGet = async (token,id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/company/single/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Single Company api failed');
};

const updateCompany = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/company/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated company api failed');
};

const authService = {
  login,
  register,
  singleCompanyGet,
  updateCompany,
  verifyEmail
};

export default authService;
